// We actually might not need models as a separate module, we could have just facade module that
// decouples our data structures from Shopify data structures. Until we implement such facade
// we will put here our models and methods that convert them to/from Shopify models

import {
  IPricingPolicyAdjustmentFromZod,
  ISellingPlanFixedPricingPolicyFromZod,
  ISellingPlanPricingPolicyFromZod,
  ISellingPlanRecurringPricingPolicyFromZod,
} from "@smartrr/shared/entities/SellingPlanGroup";
import {
  CurrencyCode,
  SellingPlanPricingPolicyAdjustmentType,
  SellingPlanPricingPolicyInput,
  SellingPlanPricingPolicyValueInput,
} from "@smartrr/shared/shopifyGraphQL/api";

export enum PricingPolicyType {
  Fixed = "fixed",
  Recurring = "recurring",
}

export interface ProductOfferingSettings {
  id?: string;
  originalProductVariantId: string;
  isActive: boolean;
  exists: boolean;
  newProductVariantId: string;
  orderNumber: number;
  sequentialEndBehavior: string;
  sequentialProducts?: string[];
}

export interface PricingPolicyModel {
  policyType: PricingPolicyType;
  adjustmentType: SellingPlanPricingPolicyAdjustmentType;
  adjustmentValue: number;
  afterCycle: number;
  // Previously created policy can be disabled and upon saving will be removed from the selling plan
  isEnabled: boolean;
}

export function buildModelFromPricingPolicy(policy: SellingPlanPricingPolicyInput): PricingPolicyModel {
  const { policyType, policyData } = policy.fixed
    ? { policyType: PricingPolicyType.Fixed, policyData: { ...policy.fixed!, afterCycle: 0 } }
    : { policyType: PricingPolicyType.Recurring, policyData: policy.recurring! };

  const adjustmentValue =
    policyData.adjustmentType === SellingPlanPricingPolicyAdjustmentType.Percentage
      ? policyData.adjustmentValue?.percentage
      : policyData.adjustmentValue?.fixedValue;

  return {
    policyType,
    adjustmentType: policyData.adjustmentType!,
    afterCycle: policyData.afterCycle,
    adjustmentValue: adjustmentValue == null ? 0 : adjustmentValue,
    isEnabled: true, // Any policy that is part of selling plan is enabled
  };
}

export function buildSmartrrPricingPolicyFromModel(model: PricingPolicyModel): ISellingPlanPricingPolicyFromZod {
  let adjustment: IPricingPolicyAdjustmentFromZod;
  switch (model.adjustmentType) {
    case SellingPlanPricingPolicyAdjustmentType.Percentage: {
      adjustment = {
        adjustmentValue: { percentage: Number(model.adjustmentValue) },
        adjustmentType: "PERCENTAGE",
      };
      break;
    }

    case SellingPlanPricingPolicyAdjustmentType.Price: {
      adjustment = {
        adjustmentValue: { amount: Number(model.adjustmentValue), currencyCode: CurrencyCode.Usd },
        adjustmentType: "PRICE",
      };
      break;
    }
    case SellingPlanPricingPolicyAdjustmentType.FixedAmount: {
      adjustment = {
        adjustmentValue: { amount: Number(model.adjustmentValue), currencyCode: CurrencyCode.Usd },
        adjustmentType: "FIXED_AMOUNT",
      };
      break;
    }

    default: {
      const exhaustiveCheck: never = model.adjustmentType;
      return exhaustiveCheck;
    }
  }

  if (model.policyType === PricingPolicyType.Recurring) {
    return {
      recurring: {
        ...adjustment,
        afterCycle: model.afterCycle,
      },
    };
  }

  return {
    fixed: {
      ...adjustment,
    },
  };
}

export function buildPricingPolicyFromModel(model: PricingPolicyModel): SellingPlanPricingPolicyInput {
  let adjustmentValue: SellingPlanPricingPolicyValueInput;
  switch (model.adjustmentType) {
    case SellingPlanPricingPolicyAdjustmentType.Percentage: {
      adjustmentValue = { percentage: Number(model.adjustmentValue) };
      break;
    }

    case SellingPlanPricingPolicyAdjustmentType.Price:
    case SellingPlanPricingPolicyAdjustmentType.FixedAmount: {
      adjustmentValue = { fixedValue: model.adjustmentValue };
      break;
    }

    default: {
      const exhaustiveCheck: never = model.adjustmentType;
      return exhaustiveCheck;
    }
  }

  return {
    [model.policyType]: {
      adjustmentType: model.adjustmentType,
      adjustmentValue,
      ...(model.policyType === PricingPolicyType.Recurring ? { afterCycle: model.afterCycle } : {}),
    },
  };
}

export const isFixedPricingPolicy = (x: any): x is ISellingPlanFixedPricingPolicyFromZod =>
  x.hasOwnProperty("fixed");

export function buildModelFromSmartrrPricingPolicy(policy: ISellingPlanPricingPolicyFromZod): PricingPolicyModel {
  let pricingPolicy: {
    policyType: PricingPolicyType;
    policyData: ISellingPlanFixedPricingPolicyFromZod | ISellingPlanRecurringPricingPolicyFromZod;
  };
  if (isFixedPricingPolicy(policy)) {
    pricingPolicy = {
      policyType: PricingPolicyType.Fixed,
      policyData: { fixed: policy.fixed },
    };
  } else {
    pricingPolicy = {
      policyType: PricingPolicyType.Recurring,
      policyData: {
        recurring: policy.recurring,
      },
    };
  }
  const { policyType, policyData } = pricingPolicy;

  const adjustmentValue =
    policyData[policyType].adjustmentType === SellingPlanPricingPolicyAdjustmentType.Percentage
      ? policyData[policyType].adjustmentValue?.percentage
      : (policyData[policyType].adjustmentValue?.amount ?? policyData[policyType].adjustmentValue?.fixedValue);

  return {
    policyType,
    adjustmentType: policyData[policyType].adjustmentType,
    afterCycle: policyData[policyType].afterCycle ?? 0,
    adjustmentValue: adjustmentValue == null ? 0 : adjustmentValue,
    isEnabled: true, // Any policy that is part of selling plan is enabled
  };
}

export function buildPricingPoliciesFromModels(models: PricingPolicyModel[]): SellingPlanPricingPolicyInput[] {
  return models.filter(model => model.isEnabled).map(model => buildPricingPolicyFromModel(model));
}

export function buildSmartrrPricingPoliciesFromModels(
  models: PricingPolicyModel[]
): ISellingPlanPricingPolicyFromZod[] {
  return models.filter(model => model.isEnabled).map(model => buildSmartrrPricingPolicyFromModel(model));
}
